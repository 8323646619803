.card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 1rem;
    
    max-width: 50rem;
    height: 450px;
  }

  .animated-card {
    transition: height 0.3s ease; /* Adjust the transition duration and easing as needed */
  }

  /* .MuiCard-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

  /* .MuiCardActions-root{
     display: flex;
     justify-content: space-between;
  } */
  .card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .card-text {
    flex: 1;
    margin-bottom: 1rem;
  }
  
  .card-link {
    padding: 0.5rem 1rem;
    background-color: #00838F;
    color: #fff;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 4px;
    text-align: center;
    transition: background-color 0.2s;
    text-decoration: none;
  }
  
  .card-link:hover {
    background-color: #20b3c0;
    color: #fff
  }

  /* 
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  
  @media (max-width: 768px) {
    .card-list {
      flex-direction: column;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .card-list {
      max-width: 768px;
    }
  }
  
  @media (min-width: 1025px) and (max-width: 1200px) {
    .card-list {
      max-width: 1024px;
      display: flex;
      justify-content: center;
    }
  }
  
  @media (min-width: 1201px) {
    .card-list {
      max-width: 1200px;
    }
  }
  
  .card-list > * {
    margin: 1rem;
    flex: 1 1 20rem;
  }
  
  @media (max-width: 768px) {
    .card-list > * {
      flex: 1 1 100%;
    }
  }
  
  .card-list > :first-child {
    margin-left: 0;
  }
  
  .card-list > :last-child {
    margin-right: 0;
  }
  
  .card-list:after {
    content: '';
    flex: 0 0 auto;
    min-width: 1rem;
  }
  
  .card-list > *:last-child:after {
    flex: 0 0 auto;
    min-width: auto;
  }
  
  .card-list > *:last-child:before {
    content: '';
    flex: 0 0 auto;
    min-width: 1rem;
  }
  
  @media (max-width: 768px) {
    .card-list > *:last-child:before {
      flex: 0 0 auto;
      min-width: auto;
    }
  } */
  