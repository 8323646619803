.custom-file-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f2f2f2;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  input[type=file]::file-selector-button {
    background-color: #ccc;
    border: 1px solid #c5c4c4;
    padding: 8px 16px;
    cursor: pointer;
  }