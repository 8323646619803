
.background {
    width: 100%;
    height: 80vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .background-sm {
    background-image: url('../assets/mycogniverse_sm.png');
  }
  
  .background-md {
    background-image: url('../assets/mycogniverse_md.png');
  }
  
  .background-lg {
    background-image: url('../assets/mycogniverse.png');
  }
  
  .content {
  
   text-align: center;
   font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
   
  }
  